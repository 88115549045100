// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';  // Ensure this import is correct

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null); // Initial state is null
//   const [patientId, setPatientId] = useState('');

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const toTitleCase = (str) => {
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (!validationResults) {
//     return <div>Loading...</div>;
//   }

//   const documentStatus = Object.keys(validationResults).map(doc => {
//     const discrepancies = validationResults[doc].validity === "invalid" ? validationResults[doc].reason.length : 0;
//     const validity = validationResults[doc].validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = toTitleCase(doc);
//     const reasons = validationResults[doc].reason.join("\n");

//     const linkTo = doc === 'tariff_document' ? `/tariff/${doc}` : `/document/${doc}`;

//     return (
//       <Link to={linkTo} key={doc} className="result-item" onClick={() => setSelectedDocId(doc)}>
//         <p>{displayText} {discrepancies > 0 && <span className="discrepancies">[{discrepancies}]</span>}</p>
//         <img src={statusImage} alt={validity} className="status-image" />
//         {discrepancies > 0 && <div className="tooltip">{reasons}</div>}
//       </Link>
//     );
//   });

//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop(); // or .split('/').pop() depending on your environment
//     return (
//       <li key={file}>
//         <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//           <DocumentIcon className="document-icon" />
//           {fileName}
//         </a>
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header showDisclaimer={false} showSearch={false} fetchResponseText={false} docId={selectedDocId} /> {/* Pass docId */}
//       <div className="details-section">
//         <div className="audit-results">
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;







// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';  // Ensure this import is correct

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null); // Initial state is null
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false); // State for blacklisted status

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/claim-data');
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True"); // Check for blacklisted status
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const toTitleCase = (str) => {
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (!validationResults) {
//     return <div>Loading...</div>;
//   }

//   const documentStatus = Object.keys(validationResults).map(doc => {
//     const discrepancies = validationResults[doc].validity === "invalid" ? validationResults[doc].reason.length : 0;
//     const validity = validationResults[doc].validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = toTitleCase(doc);
//     const reasons = validationResults[doc].reason.join("\n");

//     return (
//       <div key={doc} className={`result-item ${isBlacklisted ? 'frozen' : ''}`}>
//         <p>{displayText} {discrepancies > 0 && <span className="discrepancies">[{discrepancies}]</span>}</p>
//         <img src={statusImage} alt={validity} className="status-image" />
//         {discrepancies > 0 && <div className="tooltip">{reasons}</div>}
//       </div>
//     );
//   });

//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop(); // or .split('/').pop() depending on your environment
//     return (
//       <li key={file}>
//         {/* Disable links if blacklisted */}
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header showDisclaimer={false} showSearch={false} fetchResponseText={false} docId={selectedDocId} /> {/* Pass docId */}
      
//       {/* Display warning popup if blacklisted */}
//       {isBlacklisted && (
//         <div className="warning-popup">
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}
      
//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;



// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';  // Ensure this import is correct

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null); // Initial state is null
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false); // State for blacklisted status
//   const navigate = useNavigate(); // Use useNavigate for navigation

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/claim-data');
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True"); // Check for blacklisted status
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const toTitleCase = (str) => {
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (!validationResults) {
//     return <div>Loading...</div>;
//   }

//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//   };
  
//   const documentStatus = Object.keys(validationResults).map((doc) => {
//     const discrepancies =
//       validationResults[doc].validity === "invalid"
//         ? validationResults[doc].reason.length
//         : 0;
//     const validity =
//       validationResults[doc].validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = nameMappings[doc] || toTitleCase(doc); // Use mapped name or fallback to the original toTitleCase function
//     const reasons = validationResults[doc].reason.join("\n");
//     // const linkTo =
//     //   doc === "tariff_document" ? `/tariff/${doc}` : `/document/${doc}`;
//     const linkTo = `/document/${doc}`;
//     const isBlacklisted = false; // Update this based on your logic
  
//     return (
//       <div
//         key={doc}
//         className={`result-item ${isBlacklisted ? "frozen" : ""}`}
//         onClick={() => handleItemClick(doc)} // Handle item click
//       >
//         <Link
//           to={linkTo}
//           className="result-item-link"
//           onClick={(e) => {
//             e.stopPropagation(); // Prevent parent div click event
//             setSelectedDocId(doc);
//           }}
//         >
//           <p>
//             {displayText}{" "}
//             {discrepancies > 0 && (
//               <span className="discrepancies">[{discrepancies}]</span>
//             )}
//           </p>
//         </Link>
//         <img src={statusImage} alt={validity} className="status-image" />
//         {discrepancies > 0 && <div className="tooltip">{reasons}</div>}
//       </div>
//     );
//   });
  

//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop(); // or .split('/').pop() depending on your environment
//     return (
//       <li key={file}>
//         {/* Disable links if blacklisted */}
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header showDisclaimer={false} showSearch={false} fetchResponseText={false} docId={selectedDocId} /> {/* Pass docId */}
      
//       {/* Display warning popup if blacklisted */}
//       {isBlacklisted && (
//         <div className="warning-popup"style={{  fontSize: '20px', fontWeight:"500" }}>
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}
      
//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;


// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// const nameMappings = {
//   policy_document: 'Enrollment',
//   policy_wording_document: 'Policy Wording',
//   stg_document: 'Standard Treatment Guidelines',
//   tariff_document: 'Tariff',
//   nonpayables: 'Non Payables',
//   discrepancy_summary: 'Discrepancy Summary',
// };

// const toTitleCase = (str) => {
//   return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null);
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null); // State for ICE Authorized Amount
//   const [loading, setLoading] = useState(true); // State to track loading
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/claim-data');
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True");
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const [policyStatus, setPolicyStatus] = useState(null);

// useEffect(() => {
//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setPolicyStatus(data); // Store the policy status data
//         setIceAuthorizedAmount(data.ice_authorized_amount); // Fetch ICE Authorized Amount
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     } finally {
//       setLoading(false); // Stop loading after fetching policy status
//     }
//   };

//   fetchPolicyStatus();
// }, []);


//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   if (loading || !validationResults) {
//     return <div>Loading...</div>;
//   }

//   const documentStatus = [
//     'discrepancy_summary',
//     ...Object.keys(validationResults),
//     'nonpayables',
//   ].map((doc) => {
//     let discrepancies = 0;
//     let tooltipText = "";  // Initialize tooltip text as empty
  
//     // Define isInvalid for each document
//     const isInvalid = validationResults[doc]?.validity === "invalid";
  
//     if (policyStatus) {
//       if (doc === 'policy_wording_document' && isInvalid) {
//         discrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//       } else if (doc === 'policy_document' && isInvalid) {
//         discrepancies = 1;
//       } else if (doc === 'stg_document' && isInvalid) {
//         discrepancies = 1;
//       } else if (doc === 'tariff_document') {
//         const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
//         const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
//         const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;
  
//         discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;
  
//         // Determine validity based on discrepancies count
//         const validity = discrepancies === 0 ? "approve" : "deny";
//         const statusImage = validity === "approve" ? tickImage : crossImage;
  
//         if (discrepancies > 0) {
//           tooltipText = "Some items in the bill do not match the approved tariff rates.";
//         }
  
//         return (
//           <div
//             key={doc}
//             className={`result-item ${isBlacklisted ? "frozen" : ""}`}
//             onClick={() => handleItemClick(doc)}
//           >
//             <Link
//               to={`/document/${doc}`}
//               className="result-item-link"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedDocId(doc);
//               }}
//             >
//               <p>
//                 {nameMappings[doc] || toTitleCase(doc)}{" "}
//                 {discrepancies > 0 && (
//                   <span className="discrepancies">[{discrepancies}]</span>
//                 )}
//               </p>
//             </Link>
//             <img src={statusImage} alt={validity} className="status-image" />
//             {discrepancies > 0 && <div className="tooltip">{tooltipText}</div>}
//           </div>
//         );
//       } else if (doc === 'nonpayables') {
//         const nonPayablesCount = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;
  
//         discrepancies = nonPayablesCount;
  
//         // Determine validity based on discrepancies count
//         const validity = discrepancies === 0 ? "approve" : "deny";
//         const statusImage = validity === "approve" ? tickImage : crossImage;
  
//         if (discrepancies > 0) {
//           tooltipText = "The bill includes items that are not covered under the policy.";
//         }
  
//         return (
//           <div
//             key={doc}
//             className={`result-item ${isBlacklisted ? "frozen" : ""}`}
//             onClick={() => handleItemClick(doc)}
//           >
//             <Link
//               to={`/document/${doc}`}
//               className="result-item-link"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedDocId(doc);
//               }}
//             >
//               <p>
//                 {nameMappings[doc] || toTitleCase(doc)}{" "}
//                 {discrepancies > 0 && (
//                   <span className="discrepancies">[{discrepancies}]</span>
//                 )}
//               </p>
//             </Link>
//             <img src={statusImage} alt={validity} className="status-image" />
//             {discrepancies > 0 && <div className="tooltip">{tooltipText}</div>}
//           </div>
//         );
//       } else if (isInvalid) {
//         discrepancies = validationResults[doc]?.reason.length || 0;
//       }
//     } else if (isInvalid) {
//       discrepancies = validationResults[doc]?.reason.length || 0;
//     }
  
//     const validity = validationResults[doc]?.validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = nameMappings[doc] || toTitleCase(doc);
//     const reasons = validationResults[doc]?.reason.join("\n") || "";
//     const linkTo = `/document/${doc}`;
  
//     const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';
  
//     return (
//       <div
//         key={doc}
//         className={`${itemClass} ${isBlacklisted ? "frozen" : ""}`}
//         onClick={() => handleItemClick(doc)}
//       >
//         <Link
//           to={linkTo}
//           className="result-item-link"
//           onClick={(e) => {
//             e.stopPropagation();
//             setSelectedDocId(doc);
//           }}
//         >
//           <p>
//             {displayText}{" "}
//             {discrepancies > 0 && (
//               <span className="discrepancies">[{discrepancies}]</span>
//             )}
//           </p>
//         </Link>
//         {validationResults[doc] && (
//           <img src={statusImage} alt={validity} className="status-image" />
//         )}
//         {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
//       </div>
//     );
//   });
  
//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop();
//     return (
//       <li key={file}>
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header
//         showDisclaimer={false}
//         showSearch={false}
//         fetchResponseText={false}
//         docId={selectedDocId}
//         iceAuthorizedAmount={iceAuthorizedAmount} // Pass iceAuthorizedAmount to Header
//       />
      
//       {isBlacklisted && (
//         <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}
      
//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;











// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';
// import { API_ENDPOINTS } from '../config';

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// const nameMappings = {
//   policy_document: 'Enrollment',
//   policy_wording_document: 'Policy Wording',
//   stg_document: 'Standard Treatment Guidelines',
//   tariff_document: 'Tariff',
//   nonpayables: 'Non Payables',
//   discrepancy_summary: 'Discrepancy Summary',
// };

// const toTitleCase = (str) => {
//   return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null);
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null); // State for ICE Authorized Amount
//   const [loading, setLoading] = useState(true); // State to track loading
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/patient-id`);
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-data`);
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True");
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `${API_ENDPOINTS.EXPRESS_SERVICE}/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const [policyStatus, setPolicyStatus] = useState(null);

// useEffect(() => {
//   const fetchPolicyStatus = async () => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setPolicyStatus(data); // Store the policy status data
//         setIceAuthorizedAmount(data.ice_authorized_amount); // Fetch ICE Authorized Amount
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     } finally {
//       setLoading(false); // Stop loading after fetching policy status
//     }
//   };

//   fetchPolicyStatus();
// }, []);


//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   if (loading || !validationResults) {
//     return <div>Loading...</div>;
//   }

// // Start with the initial sum of discrepancies
// let totalDiscrepancies = Object.keys(validationResults).reduce((sum, doc) => {
//   let docDiscrepancies = 0;

//   if (doc === 'tariff_document') {
//     // Always include tariff_document discrepancies
//     const necessaryCount = policyStatus?.tariff?.necessary_items?.length || 0;
//     const nonCompliantCount = policyStatus?.tariff?.non_compliant_items?.length || 0;
//     const unnecessaryCount = policyStatus?.tariff?.unnecessary_items?.length || 0;
//     docDiscrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;
//   } else {
//     // Include discrepancies for other documents only if they are invalid
//     const isInvalid = validationResults[doc]?.validity === "invalid";
//     if (isInvalid) {
//       if (doc === 'policy_wording_document') {
//         docDiscrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//       } else if (doc === 'stg_document') {
//         docDiscrepancies = 1;  // Assuming there's one discrepancy
//         console.log(`STG Document Discrepancies: ${docDiscrepancies}`);
//       } else {
//         docDiscrepancies = validationResults[doc]?.reason.length || 0;
//       }
//     }
//   }

//   console.log(`Document: ${doc}, Discrepancies: ${docDiscrepancies}`);
//   return sum + docDiscrepancies;
// }, 0);

// // Manually add nonpayables discrepancies
// const nonpayablesDiscrepancies = policyStatus?.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;
// totalDiscrepancies += nonpayablesDiscrepancies;
// console.log(`Nonpayables Discrepancies Manually Added: ${nonpayablesDiscrepancies}`);
// console.log(`Total Discrepancies Calculated: ${totalDiscrepancies}`);

// totalDiscrepancies = 0
// // Add the length of Missed Deductions to the total discrepancies
// const missedDeductionsCount = policyStatus?.Missed_Deductions?.length || 0;
// totalDiscrepancies += missedDeductionsCount;  // Add missed deductions count to the total

  
  
  
// const documentStatus = [
//   'discrepancy_summary',
//   ...Object.keys(validationResults),
//   'nonpayables',
// ].map((doc) => {
//   let discrepancies = 0;
//   let tooltipText = "";

//   let validity = "deny";  // Default validity to "deny" (cross)
//   const isInvalid = validationResults[doc]?.validity === "invalid";

//   if (policyStatus) {
//     // Logic for specific documents:
//     if (doc === 'policy_document') {
//       // If the policy document is valid, tick, otherwise cross
//       validity = policyStatus.policy_document?.status?.valid ? "approve" : "deny";
      
//       // Add discrepancy count as "1" if policy_document is invalid
//       if (validity === "deny") {
//         discrepancies = 1;  // Set 1 discrepancy if invalid
//       }
//     } 
//     else if (doc === 'policy_wording_document') {
//       const {
//         sub_limit,
//         proportional_deduction,
//         total_deductible,
//         total_copayment
//       } = policyStatus.policy_wording_document || {};
      
//       // If all these values are 0, tick, otherwise cross
//       const allZero = sub_limit === 0 && proportional_deduction === 0 && total_deductible === 0 && total_copayment === 0;
//       validity = allZero ? "approve" : "deny";

//       if (isInvalid) {
//         discrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//       }
//     } 
//     else if (doc === 'stg_document') {
//       // If alignment percentage is greater than 70, tick, otherwise cross
//       const alignmentPercentage = policyStatus.stg_document?.alignment_percentage || 0;
//       validity = alignmentPercentage > 70 ? "approve" : "deny";

//       if (isInvalid) {
//         discrepancies = 1;  // Assuming one discrepancy if invalid
//       }
//     } 
//     else if (doc === 'tariff_document') {
//       const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
//       const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
//       const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;

//       discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;

//       // Set custom tooltip text for Tariff
//       if (discrepancies > 0) {
//         tooltipText = "Some items in the bill do not match the approved tariff rates.";
//       }
//     } 
//     else if (doc === 'nonpayables') {
//       discrepancies = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;

//       // Set custom tooltip text for Non Payables
//       if (discrepancies > 0) {
//         tooltipText = "The bill includes items that are not covered under the policy.";
//       }
//     } 
//     else if (isInvalid) {
//       discrepancies = validationResults[doc]?.reason.length || 0;
//     }

//     // Log the discrepancies calculated for this document
//     console.log(`Rendering Document: ${doc}, Discrepancies: ${discrepancies}`);
//   }

//   const statusImage = validity === "approve" ? tickImage : crossImage;

//   const displayText = doc === 'discrepancy_summary'
//     ? <>
//         {nameMappings[doc] || toTitleCase(doc)} <span style={{ color: 'red' }}>[{totalDiscrepancies}]</span>
//       </>
//     : nameMappings[doc] || toTitleCase(doc);

//   const reasons = validationResults[doc]?.reason.join("\n") || "";
//   const linkTo = `/document/${doc}`;

//   const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';

//   return (
//     <div
//       key={doc}
//       className={`${itemClass} ${isBlacklisted ? "frozen" : ""}`}
//       onClick={() => handleItemClick(doc)}
//     >
//       <Link
//         to={linkTo}
//         className="result-item-link"
//         onClick={(e) => {
//           e.stopPropagation();
//           setSelectedDocId(doc);
//         }}
//       >
//         <p>
//           {displayText}{" "}
//           {validity === "deny" && discrepancies > 0 && ( // Only show discrepancies if it's invalid
//             <span 
//               className="discrepancies" 
//               style={{ color: 'red' }}  // Apply red color to all discrepancies
//             >
//               [{discrepancies}]
//             </span>
//           )}
//         </p>
//       </Link>
//       {doc !== 'discrepancy_summary' && (
//         <img src={statusImage} alt={validity} className="status-image" />
//       )}
//       {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
//     </div>
//   );
// });



// console.log(`Document Status Rendered with Total Discrepancies: ${totalDiscrepancies}`);


// const documentLinks = files.map(file => {
//   // const fileName = file.split('\\').pop();
//   // const fileName = file.split('/').pop();
//   const fileName = file.split(/[/\\]/).pop();
//   return (
//     <li key={file}>
//       {isBlacklisted ? (
//         <span className="frozen-document">
//           <DocumentIcon className="document-icon" />
//           {fileName}
//         </span>
//       ) : (
//         <a href={`${API_ENDPOINTS.EXPRESS_SERVICE}/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//           <DocumentIcon className="document-icon" />
//           {fileName}
//         </a>
//       )}
//     </li>
//   );
// });

// return (
//   <div className="claim-details-page">
//     <Header
//       showDisclaimer={false}
//       showSearch={false}
//       fetchResponseText={false}
//       docId={selectedDocId}
//       iceAuthorizedAmount={iceAuthorizedAmount} // Pass iceAuthorizedAmount to Header
//     />
    
//     {isBlacklisted && (
//       <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
//         <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//       </div>
//     )}
    
//     {/* New div for Discrepancy Summary */}
//     <div className="discrepancy-summary-container">
//       <div className="discrepancy-summary">
//         {documentStatus[0]} {/* This assumes the first element is the Discrepancy Summary */}
//       </div>
//     </div>

//     <div className="details-section">
      
//       <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//       <h2 className="ice-deductions-heading">ICE Deductions</h2>
//         {documentStatus.slice(1)} {/* All the other document statuses */}
//       </div>
//       <div className="documents">
      
//         <ul>
//           {documentLinks}
//         </ul>
//       </div>
//     </div>
//   </div>
// );
// }

// export default ClaimDetailsPage;




// ClaimDetailsPage.js

// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';
// import { API_ENDPOINTS } from '../config';

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// const nameMappings = {
//   policy_document: 'Enrollment',
//   policy_wording_document: 'Policy Wording',
//   stg_document: 'Standard Treatment Guidelines',
//   tariff_document: 'Tariff',
//   nonpayables: 'Non Payables',
//   discrepancy_summary: 'Discrepancy Summary',
// };

// const toTitleCase = (str) => {
//   return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null);
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const [policyStatus, setPolicyStatus] = useState(null);

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/patient-id`);
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-data`);
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True");
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `${API_ENDPOINTS.EXPRESS_SERVICE}/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   useEffect(() => {
//     const fetchPolicyStatus = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json; charset=UTF-8',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setPolicyStatus(data);
//           setIceAuthorizedAmount(data.ice_authorized_amount);
//         } else {
//           console.error('Failed to fetch policy status', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching policy status:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPolicyStatus();
//   }, []);

//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   if (loading || !validationResults) {
//     return <div>Loading...</div>;
//   }

//   // Calculate total discrepancies
//   let totalDiscrepancies = 0;
//   const missedDeductionsCount = policyStatus?.Missed_Deductions?.length + policyStatus?.Mispriced_Deductions?.length || 0;
//   totalDiscrepancies += missedDeductionsCount;

//   // Map over the documents to create their status representations
//   const documentStatus = [
//     'discrepancy_summary',
//     ...Object.keys(validationResults),
//     'nonpayables',
//   ].map((doc) => {
//     let discrepancies = 0;
//     let tooltipText = "";

//     let validity = "deny"; // Default validity
//     const isInvalid = validationResults[doc]?.validity === "invalid";

//     if (policyStatus) {
//       // Logic for specific documents
//       if (doc === 'policy_document') {
//         validity = policyStatus.policy_document?.status?.valid ? "approve" : "deny";

//         if (validity === "deny") {
//           discrepancies = 1;
//         }
//       } else if (doc === 'policy_wording_document') {
//         const {
//           sub_limit,
//           proportional_deduction,
//           total_deductible,
//           total_copayment
//         } = policyStatus.policy_wording_document || {};

//         const allZero = sub_limit === 0 && proportional_deduction === 0 && total_deductible === 0 && total_copayment === 0;
//         validity = allZero ? "approve" : "deny";

//         if (isInvalid) {
//           discrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//         }
//       } else if (doc === 'stg_document') {
//         const alignmentPercentage = policyStatus.stg_document?.alignment_percentage || 0;
//         validity = alignmentPercentage > 70 ? "approve" : "deny";

//         if (isInvalid) {
//           discrepancies = 1;
//         }
//       } else if (doc === 'tariff_document') {
//         const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
//         const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
//         const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;

//         discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;

//         if (discrepancies > 0) {
//           tooltipText = "Some items in the bill do not match the approved tariff rates.";
//         }
//       } else if (doc === 'nonpayables') {

//         // Existing non-payable discrepancies
//         const existingNonPayableDiscrepancies = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;

//         // Newly added discrepancies
//         const addedDiscrepancies = policyStatus.nonpayables?.discrepancy_count || 0;

//         discrepancies = existingNonPayableDiscrepancies + addedDiscrepancies;


//         discrepancies = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;

//         if (discrepancies > 0) {
//           tooltipText = "The bill includes items that are not covered under the policy.";
//         }
//       } else if (isInvalid) {
//         discrepancies = validationResults[doc]?.reason.length || 0;
//       }
//     }

//     const statusImage = validity === "approve" ? tickImage : crossImage;

//     const displayText = doc === 'discrepancy_summary'
//       ? <>
//           {nameMappings[doc] || toTitleCase(doc)} <span style={{ color: 'red' }}>[{totalDiscrepancies}]</span>
//         </>
//       : nameMappings[doc] || toTitleCase(doc);

//     const reasons = validationResults[doc]?.reason.join("\n") || "";
//     const linkTo = `/document/${doc}`;

//     const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';

//     // Determine if the document should be clickable
//     const isClickable = validity !== "approve";

//     // Apply a different class if the document is valid
//     const itemClasses = `${itemClass} ${isClickable ? '' : 'valid-document'} ${isBlacklisted ? 'frozen' : ''}`;

//     return (
//       <div
//         key={doc}
//         className={itemClasses}
//         onClick={() => {
//           if (isClickable && !isBlacklisted) {
//             handleItemClick(doc);
//           }
//         }}
//       >
//         {isClickable ? (
//           <Link
//             to={linkTo}
//             className="result-item-link"
//             onClick={(e) => {
//               e.stopPropagation();
//               setSelectedDocId(doc);
//             }}
//           >
//             <p>
//               {displayText}{" "}
//               {validity === "deny" && discrepancies > 0 && (
//                 // <span
//                 //   className="discrepancies"
//                 //   style={{ color: 'red' }}
//                 // >
//                 //   [{discrepancies}]
//                 // </span>

//                 <>
//                 <span className="discrepancies" style={{ color: 'red' }}>
//                   [{existingNonPayableDiscrepancies}]
//                 </span>
//                 {addedDiscrepancies > 0 && (
//                   <span className="added-discrepancies" style={{ color: 'blue' }}>
//                     {` +[${addedDiscrepancies}]`}
//                   </span>
//                 )}
//               </>
//               )}
//             </p>
//           </Link>
//         ) : (
//           <p className="non-clickable-document">
//             {displayText}{" "}
//             {validity === "deny" && discrepancies > 0 && (
//               <span
//                 className="discrepancies"
//                 style={{ color: 'red' }}
//               >
//                 [{discrepancies}]
//               </span>
//             )}
//           </p>
//         )}
//         {doc !== 'discrepancy_summary' && (
//           <img src={statusImage} alt={validity} className="status-image" />
//         )}
//         {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
//       </div>
//     );
//   });

//   const documentLinks = files.map(file => {
//     const fileName = file.split(/[/\\]/).pop();
//     return (
//       <li key={file}>
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`${API_ENDPOINTS.EXPRESS_SERVICE}/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header
//         showDisclaimer={false}
//         showSearch={false}
//         fetchResponseText={false}
//         docId={selectedDocId}
//         iceAuthorizedAmount={iceAuthorizedAmount}
//       />

//       {isBlacklisted && (
//         <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}

//       {/* Discrepancy Summary */}
//       {/* <div className="discrepancy-summary-container">
//         <div className="discrepancy-summary">
//           {documentStatus[0]}
//         </div>
//       </div> */}

//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           <h2 className="ice-deductions-heading">ICE Deductions</h2>
//           {documentStatus.slice(1)}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;





import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate , useLocation, useSearchParams} from 'react-router-dom';
import Header from './Header';
import './ClaimDetailsPage.css';
import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';
import { API_ENDPOINTS } from '../config';
import lockicon from '../icons/padlock_1073162.png'
import CryptoJS from 'crypto-js'; // Import crypto-js for decryption
import "tippy.js/dist/tippy.css"; // Tippy's styling
import Tippy from "@tippyjs/react";
// import fernet from 'fernet';
const tickImage = process.env.PUBLIC_URL + '/tick.png';
const crossImage = process.env.PUBLIC_URL + '/cross.png';



const nameMappings = {
  policy_document: 'Enrollment',
  policy_wording_document: 'Policy Wording',
  stg_document: 'Standard Treatment Guidelines',
  tariff_document: 'Tariff',
  nonpayables: 'Non Payables',
  discrepancy_summary: 'Discrepancy Summary',
};

const toTitleCase = (str) => {
  return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const PASSPHRASE = "sp-EmW3c-S0AsQL8ieRSLjuPo31ZYeiSbwJYdTcKCdQ="
// Decrypt the query parameters
// export function decrypt(encryptedText) {
//   try {
//     const secret = new fernet.Secret(SECRET_KEY);
//     const token = new fernet.Token({
//       secret: secret,
//       token: encryptedText,
//       ttl: 0 // unlimited by default
//     });
//     const decoded = token.decode();
//     return decoded; // This is the original plaintext
//   } catch (error) {
//     console.error("Decryption failed:", error);
//     return null;
//   }
// }

function ClaimDetailsPage() {
  const [searchParams] = useSearchParams();
  const [validationResults, setValidationResults] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [patientId, setPatientId] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const hasFetchedRef = useRef(false); // Track if the final API call has been made
  const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [policyStatus, setPolicyStatus] = useState(null);

  // Retrieve patientId and claimId from location.state or sessionStorage
  const location = useLocation();
  const freezeTooltipText = "To Access 🔑 the full version contact @icehealth.in";
  // const passedPatientId = location.state?.patientId || sessionStorage.getItem("patientId");
  // const passedClaimId = location.state?.claimId || sessionStorage.getItem("claimId");
  // const passedPatientId = "ICE-A0D9D823"
  // const passedClaimId = "CLAIM-0C654916"
  // 1. Grab them from the query string:
  // const urlpatientId = searchParams.get('patientId');
  // const urlclaimId   = searchParams.get('claimId');

  // Retrieve encrypted parameters from URL
  const encryptedPatientId = searchParams.get('patientId');
  const encryptedClaimId = searchParams.get('claimId');

  // Decrypt the parameters
  const urlpatientId = CryptoJS.AES.decrypt(encryptedPatientId, PASSPHRASE)
  .toString(CryptoJS.enc.Utf8);
  const urlclaimId = CryptoJS.AES.decrypt(encryptedClaimId, PASSPHRASE)
  .toString(CryptoJS.enc.Utf8);

  // 2. Fall back to sessionStorage if not present in the URL:
  // const passedPatientId = urlpatientId || sessionStorage.getItem('patientId') || location.state?.patientId ;
  // const passedClaimId   =urlclaimId  || sessionStorage.getItem('claimId') || location.state?.claimId ;
  // this will only accept the session patient id and claim id
  const passedPatientId = sessionStorage.getItem('patientId') || location.state?.patientId ;
  const passedClaimId   = sessionStorage.getItem('claimId') || location.state?.claimId ;
  // const passedPatientId = urlpatientId;
  // const passedClaimId   =urlclaimId ;
  // const passedPatientId = "ICE-A0D9D823"
  // const passedClaimId = "CLAIM-0C654916"

  // console.log(`patientId: ${passedPatientId}, claimId: ${passedClaimId}`);

  // save the id to the session storage
  useEffect(() => {
    // Only store once if not already set
    if (!sessionStorage.getItem('patientId') && passedPatientId) {
      sessionStorage.setItem('patientId', passedPatientId);
    }
    if (!sessionStorage.getItem('claimId') && passedClaimId) {
      sessionStorage.setItem('claimId', passedClaimId);
    }
  }, [passedPatientId, passedClaimId]);
  
  // https://app.icehealth.in:5000/analyze_documents/ICE-E186573F/CLAIM-8A99E651
  // useEffect(() => {
  //   if (patientId) {
  //     setPatientId(patientId);
  //   }
  // }, [patientId]);
  
  // useEffect(() => {
  //   const fetchPatientId = async () => {
  //     try {
  //       const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/patient-id`);
  //       const data = await response.json();
  //       setPatientId(data.patientId);
  //     } catch (error) {
  //       console.error('Error fetching patient ID:', error);
  //     }
  //   };

  //   fetchPatientId();
  // }, []);

  // useEffect(() => {
  //   const fetchClaimData = async () => {
  //     try {
  //       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-data`);
  //       const data = await response.json();
  //       setIsBlacklisted(data.blacklisted_hospital === "True");
  //     } catch (error) {
  //       console.error('Error fetching claim data:', error);
  //     }
  //   };

  //   fetchClaimData();
  // }, []);

  useEffect(() => {
    const fetchClaimData = async () => {
      // Ensure patientId and claimId are available
      if (!passedPatientId || !passedClaimId) {
        console.error("Missing patientId or claimId");
        return;
      }

      try {
        const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
        
        const response = await fetch(url);
        const data = await response.json();
        
        setIsBlacklisted(data.blacklisted_hospital === "True");
      } catch (error) {
        console.error('Error fetching claim data:', error);
      }
    };

    fetchClaimData();
  }, [passedPatientId, passedClaimId]);  // Add as dependencies

  // useEffect(() => {
  //   const fetchValidationResults = async () => {
  //     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
  //     try {
  //       const response = await fetch(url, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         setValidationResults(data.validation_results);
  //       } else {
  //         console.error('Failed to fetch validation results', await response.text());
  //       }
  //     } catch (error) {
  //       console.error('Error fetching validation results:', error);
  //     }
  //   };

  //   fetchValidationResults();
  // }, []);

  useEffect(() => {
    const fetchValidationResults = async () => {
      if (!passedPatientId || !passedClaimId) {
        console.error("Missing patientId or claimId");
        return;
      }

      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setValidationResults(data.validation_results);
        } else {
          console.error('Failed to fetch validation results', await response.text());
        }
      } catch (error) {
        console.error('Error fetching validation results:', error);
      }
    };

    fetchValidationResults();
  }, [passedPatientId, passedClaimId]);  // Add as dependencies


  useEffect(() => {
    if (!passedPatientId || !passedClaimId) return;

    const fetchFiles = async () => {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/files?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setFiles(data);  // Set files as a dictionary of document types with URLs
        } else {
          console.error('Failed to fetch files', await response.text());
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, [passedPatientId, passedClaimId]);


  // useEffect(() => {
  //   const fetchPolicyStatus = async () => {
  //     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
  //     try {
  //       const response = await fetch(url, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json; charset=UTF-8',
  //         },
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         setPolicyStatus(data);
  //         setIceAuthorizedAmount(data.ice_authorized_amount);
  //       } else {
  //         console.error('Failed to fetch policy status', await response.text());
  //       }
  //     } catch (error) {
  //       console.error('Error fetching policy status:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPolicyStatus();
  // }, []);


// correct code version below it has the code for final call to reload the page
//   useEffect(() => {
//     const fetchPolicyStatus = async () => {
//       if (!passedPatientId || !passedClaimId) {
//         console.error("Missing patientId or claimId");
//         return;
//       }

//       // Include patientId and claimId as query parameters in the URL
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json; charset=UTF-8',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setPolicyStatus(data);
//           setIceAuthorizedAmount(data.ice_authorized_amount);
//         } else {
//           console.error('Failed to fetch policy status', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching policy status:', error);
//       } finally {
//         setLoading(false);
// }
// };

//     fetchPolicyStatus();
//   }, [passedPatientId, passedClaimId]); // Add as dependencies


// reload the page
useEffect(() => {
  const fetchPolicyStatus = async () => {
    if (!passedPatientId || !passedClaimId) {
      console.error("Missing patientId or claimId");
      return;
    }

    // Include patientId and claimId as query parameters in the URL
    const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPolicyStatus(data);
        setIceAuthorizedAmount(data.ice_authorized_amount);
      } else {
        console.error('Failed to fetch policy status', await response.text());
      }
    } catch (error) {
      console.error('Error fetching policy status:', error);
    } finally {
      setLoading(false);

      // Only make the final API call if it hasn't been made yet
      if (!hasFetchedRef.current) {
        try {
          const finalResponse = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/hello`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (finalResponse.ok) {
            console.log('Final API call successful, reloading page...');
            hasFetchedRef.current = true; // Mark as fetched

            // Use sessionStorage to ensure the reload happens only once
            if (!sessionStorage.getItem('hasReloaded')) {
              sessionStorage.setItem('hasReloaded', 'true'); // Mark as reloaded
              window.location.reload(); // Reload the page
            }
          } else {
            console.error('Final API call failed', await finalResponse.text());
          }
        } catch (error) {
          console.error('Error in final API call:', error);
        }
      }
    }
  };

  fetchPolicyStatus();
}, [passedPatientId, passedClaimId]); // Dependencies





  const handleItemClick = (doc) => {
    if (!isBlacklisted) {
      setSelectedDocId(doc);

      navigate(`/document/${doc}`);
      // navigate({
      //   pathname: `/document/${doc}`,
      //   search: `?patientId=${passedPatientId}&claimId=${passedClaimId}`,
      // });
    }
  };

  if (loading || !validationResults || !policyStatus) {
    return <div>Loading the details reload the page...</div>;
  }

  // Calculate total discrepancies
  let totalDiscrepancies = 0;
  const missedDeductionsCount = policyStatus?.Missed_Deductions?.length + policyStatus?.Mispriced_Deductions?.length || 0;
  totalDiscrepancies += missedDeductionsCount;


  // ***************************** Proper version *************************


  // Map over the documents to create their status representations
  const documentStatus = [
    'discrepancy_summary',
    ...Object.keys(validationResults),
    'nonpayables',
  ].map((doc) => {
    let discrepancies = 0;
    let tooltipText = "";
    let addedDiscrepancies = 0; // Define addedDiscrepancies here
    let tariffaddedDiscrepancies = 0;

    let validity = "deny"; // Default validity
    const isInvalid = validationResults[doc]?.validity === "invalid";

    if (policyStatus) {
      // Logic for specific documents
      if (doc === 'policy_document') {
        validity = policyStatus.policy_document?.status?.valid ? "approve" : "deny";

        if (validity === "deny") {
          discrepancies = 1;
        }
      } 
      
      else if (doc === 'policy_wording_document') {
        const {
            sub_limit = 0,
            proportional_deduction = 0,
            total_deductible = 0,
            total_copayment = 0
        } = policyStatus.policy_wording_document || {};
    
        // Check if all values are zero
        const allZero = sub_limit === 0 && proportional_deduction === 0 && total_deductible === 0 && total_copayment === 0;
    
        // Set validity based on whether all values are zero
        validity = allZero ? "approve" : "deny";
    
        // Calculate discrepancies (only if not allZero)
        discrepancies = allZero
            ? 0 // No discrepancies if everything is zero
            : [sub_limit, proportional_deduction, total_deductible, total_copayment].filter(value => value > 0).length;
    
        // // Debugging (optional)
        // console.log("Validity:", validity);
        // console.log("Discrepancies:", discrepancies);
    }
    
      
    else if (doc === 'stg_document') {
      // Retrieve alignment percentage with a default of 0
      const alignmentPercentage = policyStatus.stg_document?.alignment_percentage || 0;
  
      // Set validity based on alignment percentage
      validity = alignmentPercentage > 70 ? "approve" : "deny";
  
      // Use numeric value for discrepancies
      if (alignmentPercentage <= 70) {
          discrepancies = `${alignmentPercentage}%`; // Numeric value only
      } else {
          discrepancies = 0; // No discrepancies if valid
      }
  
      
  }
  
      else if (doc === 'tariff_document') {
        const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
        const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
        const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;

        discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;
        // Newly added discrepancies
        tariffaddedDiscrepancies = policyStatus.tariff?.discrepancy_count || 0;
        

        if (discrepancies > 0) {
          tooltipText = "Some items in the bill do not match the approved tariff rates.";
        }
      } 
      // else if (doc === 'nonpayables') {
      //   // Existing non-payable discrepancies
      //   const existingNonPayableDiscrepancies = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;

      //   // Newly added discrepancies
      //   addedDiscrepancies = policyStatus.nonpayables?.discrepancy_count || 0;

      //   discrepancies = existingNonPayableDiscrepancies 

      //   if (discrepancies > 0) {
      //     tooltipText = "The bill includes items that are not covered under the policy.";
      //   }

      // } 
        else if (doc === 'nonpayables') {
          // Extract the items_non_payable_but_billed_in_the_bill array
          const items = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill || [];
      
          // Count items where value is greater than 0
          discrepancies = items.filter(item => item.value > 0).length;
      
          // Newly added discrepancies
          const addedDiscrepancies = policyStatus.nonpayables?.discrepancy_count || 0;
      
          // Add newly added discrepancies (if any)
          discrepancies += addedDiscrepancies;
      
          if (discrepancies > 0) {
              tooltipText = "The bill includes items that are not covered under the policy.";
          }
      }
      
      else if (isInvalid) {
        discrepancies = validationResults[doc]?.reason.length || 0;
      }
    }

    const statusImage = validity === "approve" ? tickImage : crossImage;

    const displayText = doc === 'discrepancy_summary'
      ? <>
          {nameMappings[doc] || toTitleCase(doc)} <span style={{ color: 'red' }}>[{totalDiscrepancies}]</span>
        </>
      : nameMappings[doc] || toTitleCase(doc);

      const reasons = Array.isArray(validationResults[doc]?.reason)
      ? validationResults[doc].reason.join("\n") // If reason is an array, join the elements
      : String(validationResults[doc]?.reason || ""); // Otherwise, convert to string or use a default
  
    const linkTo = `/document/${doc}`;

    const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';

    // Determine if the document should be clickable
    const isClickable = validity !== "approve";

    // Apply a different class if the document is valid
    const itemClasses = `${itemClass} ${isClickable ? '' : 'valid-document'} ${isBlacklisted ? 'frozen' : ''}`;

    return (
      <div
        key={doc}
        className={itemClasses}
        onClick={() => {
          if (isClickable && !isBlacklisted) {
            handleItemClick(doc);
          }
        }}
      >
        {isClickable ? (
          <Link
            to={linkTo}
            className="result-item-link"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDocId(doc);
            }}
          >
            <p>
            {displayText}{" "}
            {validity === "deny" && discrepancies && (
              <>
                <span className="discrepancies" style={{ color: 'red' }}>
                  [{discrepancies}]
                </span>

                 {/* Ensure separate rendering for nonpayables and tariff_document */}
                 {doc === 'nonpayables' && addedDiscrepancies > 0 && (
                   <span className="added-discrepancies" style={{ color: '#5289ff' }}>
                     {` +[${addedDiscrepancies}]`}
                   </span>
                 )}
                 {doc === 'tariff_document' && tariffaddedDiscrepancies > 0 && (
                   <span className="added-discrepancies" style={{ color: '#5289ff' }}>
                     {` +[${tariffaddedDiscrepancies}]`}
                   </span>
                 )}
               </>
              )}
            </p>
          </Link>
        ) : (
          <p className="non-clickable-document">
            {displayText}{" "}
            {validity === "deny" && discrepancies > 0 && (
              <span
                className="discrepancies"
                style={{ color: 'red' }}
              >
                [{discrepancies}]
              </span>
            )}
          </p>
        )}
        {doc !== 'discrepancy_summary' && (
          <img src={statusImage} alt={validity} className="status-image" />
        )}
        {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
      </div>
    );
  });
 
  // ***********************************************************************************


  // ****************** Freeze Version **********************************
  // The same array map, unchanged:
  // const documentStatus = [
  //   "discrepancy_summary",
  //   ...Object.keys(validationResults),
  //   "nonpayables",
  // ].map((doc) => {
  //   let discrepancies = 0;
  //   let tooltipText = "";
  //   let addedDiscrepancies = 0;
  //   let tariffaddedDiscrepancies = 0;
  //   let validity = "deny"; // Default validity
  //   const isInvalid = validationResults[doc]?.validity === "invalid";

  //   if (policyStatus) {
  //     // Your existing doc-specific logic:
  //     if (doc === "policy_document") {
  //       validity = policyStatus.policy_document?.status?.valid ? "approve" : "deny";
  //       if (validity === "deny") discrepancies = 1;
  //     } else if (doc === "policy_wording_document") {
  //       const {
  //         sub_limit = 0,
  //         proportional_deduction = 0,
  //         total_deductible = 0,
  //         total_copayment = 0,
  //       } = policyStatus.policy_wording_document || {};
  //       const allZero =
  //         sub_limit === 0 &&
  //         proportional_deduction === 0 &&
  //         total_deductible === 0 &&
  //         total_copayment === 0;
  //       validity = allZero ? "approve" : "deny";
  //       discrepancies = allZero
  //         ? 0
  //         : [sub_limit, proportional_deduction, total_deductible, total_copayment].filter(
  //             (value) => value > 0
  //           ).length;
  //     } else if (doc === "stg_document") {
  //       const alignmentPercentage =
  //         policyStatus.stg_document?.alignment_percentage || 0;
  //       validity = alignmentPercentage > 70 ? "approve" : "deny";
  //       discrepancies = alignmentPercentage <= 70 ? `${alignmentPercentage}%` : 0;
  //     } else if (doc === "tariff_document") {
  //       const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
  //       const nonCompliantCount =
  //         policyStatus.tariff?.non_compliant_items?.length || 0;
  //       const unnecessaryCount =
  //         policyStatus.tariff?.unnecessary_items?.length || 0;
  //       discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;
  //       tariffaddedDiscrepancies = policyStatus.tariff?.discrepancy_count || 0;
  //       if (discrepancies > 0) {
  //         tooltipText = "Some items in the bill do not match the approved tariff rates.";
  //       }
  //     } else if (doc === "nonpayables") {
  //       const items =
  //         policyStatus.nonpayables?.categories
  //           ?.items_non_payable_but_billed_in_the_bill || [];
  //       discrepancies = items.filter((item) => item.value > 0).length;
  //       addedDiscrepancies = policyStatus.nonpayables?.discrepancy_count || 0;
  //       discrepancies += addedDiscrepancies;
  //       if (discrepancies > 0) {
  //         tooltipText = "The bill includes items that are not covered under the policy.";
  //       }
  //     } else if (isInvalid) {
  //       discrepancies = validationResults[doc]?.reason.length || 0;
  //     }
  //   }

  //   // Decide status icon
  //   let statusImage;
  //   if (doc === "nonpayables") {
  //     // For nonpayables: tick or cross
  //     statusImage = validity === "approve" ? tickImage : crossImage;
  //   } else {
  //     // Everything else locked
  //     statusImage = lockicon;
  //   }

  //   // Display text
  //   const displayText =
  //     doc === "discrepancy_summary" ? (
  //       <>
  //         Discrepancy Summary{" "}
  //         <span style={{ color: "red" }}>[{totalDiscrepancies}]</span>
  //       </>
  //     ) : (
  //       doc
  //     );

  //   // Only "nonpayables" is clickable
  //   const isClickable = doc === "nonpayables";
  //   const linkTo = isClickable ? `/document/${doc}` : null;

  //   return doc === "nonpayables" ? (
  //     <div
  //       key={doc}
  //       className={`result-item ${isClickable ? "" : "frozen"}`}
  //       style={{ display: "flex", alignItems: "center", height: "50px" }} // Ensure row content is vertically centered
  //       onClick={(e) => {
  //         e.stopPropagation();
  //         // If NOT clickable => do nothing
  //         if (isClickable) {
  //           handleItemClick(doc);
  //         }
  //       }}
  //     >
  //       {isClickable ? (
  //         <Link
  //           to={linkTo}
  //           className="result-item-link"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             setSelectedDocId(doc);
  //           }}
  //         >
  //           <p>
  //             {displayText}{" "}
  //             {validity === "deny" && discrepancies && (
  //               <span className="discrepancies" style={{ color: "red" }}>
  //                 [{discrepancies}]
  //               </span>
  //             )}
  //           </p>
  //         </Link>
  //       ) : (
  //         <p className="non-clickable-document" style={{ margin: 0 }}>
  //           {displayText}{" "}
  //           {validity === "deny" && discrepancies > 0 && (
  //             <span className="discrepancies" style={{ color: "red" }}>
  //               [{discrepancies}]
  //             </span>
  //           )}
  //         </p>
  //       )}
    
  //       {doc !== "discrepancy_summary" && (
  //         <img src={statusImage} alt={validity} className="status-image" />
  //       )}
    
  //       {/* Show the manual tooltip ONLY for nonpayables */}
  //       {doc === "nonpayables" && discrepancies > 0 && (
  //         <div className="tooltip">{tooltipText}</div>
  //       )}
  //     </div>
  //   ) : (
  //     <Tippy
  //       content="To Access 🔑 the full version contact @icehealth.in"
  //       placement="top"
  //       popperOptions={{
  //         modifiers: [
  //           {
  //             name: "offset",
  //             options: {
  //               offset: [-10*1, -294*1], // Adjust the horizontal and vertical offsets
  //             },
  //           },
  //           {
  //             name: "preventOverflow",
  //             options: {
  //               boundary: "viewport", // Prevent tooltip from overflowing out of the viewport
  //             },
  //           },
  //         ],
  //       }}
  //       key={doc}
  //     >
  //       <div
  //         className={`result-item ${isClickable ? "" : "frozen"}`}
  //         style={{ display: "flex", alignItems: "center", height: "50px" }} // Ensure row content is vertically centered
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           // If NOT clickable => do nothing
  //           if (isClickable) {
  //             handleItemClick(doc);
  //           }
  //         }}
  //       >
  //         {isClickable ? (
  //           <Link
  //             to={linkTo}
  //             className="result-item-link"
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               setSelectedDocId(doc);
  //             }}
  //           >
  //             <p>
  //               {displayText}{" "}
  //               {validity === "deny" && discrepancies && (
  //                 <span className="discrepancies" style={{ color: "red" }}>
  //                   [{discrepancies}]
  //                 </span>
  //               )}
  //             </p>
  //           </Link>
  //         ) : (
  //           <p className="non-clickable-document" style={{ margin: 0 }}>
  //             {displayText}{" "}
  //             {validity === "deny" && discrepancies > 0 && (
  //               <span className="discrepancies" style={{ color: "red" }}>
  //                 [{discrepancies}]
  //               </span>
  //             )}
  //           </p>
  //         )}
    
  //         {doc !== "discrepancy_summary" && (
  //           <img src={statusImage} alt={validity} className="status-image" />
  //         )}
  //       </div>
  //     </Tippy>
  //   );
    
  //   });
  
  // *************************************** Proper version for document links ***********
  // Render document links grouped by document type
  const documentLinks = Object.entries(files).flatMap(([docType, fileUrls]) =>
    fileUrls.map((fileUrl, index) => {
      const fileName = decodeURIComponent(fileUrl.split('/').pop().split('?')[0]); // Extract and decode the file name
      return (
        <li key={`${docType}-${index}`}>
          {isBlacklisted ? (
            <span className="frozen-document">
              <DocumentIcon className="document-icon" />
              {fileName}
            </span>
          ) : (
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <DocumentIcon className="document-icon" />
              {fileName}
            </a>
          )}
        </li>
      );
    })
  );
  
  // ***********************************************************************************



  // **************************** freeze version for document links ************************

  // const documentLinks = Object.entries(files).flatMap(([docType, fileUrls]) =>
  //   fileUrls.map((fileUrl, index) => {
  //     const fileName = decodeURIComponent(fileUrl.split('/').pop().split('?')[0]);
  //     const isInteractive = docType === 'claim_document'; // Only make nonpayables interactive
  
  //     return (
  //       <li key={`${docType}-${index}`}>
  //         {isInteractive ? (
  //           <a href={fileUrl} target="_blank" rel="noopener noreferrer">
  //             <DocumentIcon className="document-icon" />
  //             {fileName}
  //           </a>
  //         ) : (
  //           <span className="frozen-document">
  //             <DocumentIcon className="document-icon" />
  //             {fileName}
  //           </span>
  //         )}
  //       </li>
  //     );
  //   })
  // );
  

  return (
    <div className="claim-details-page">
      <Header
          patientId={passedPatientId} // Pass patientId
          claimId={passedClaimId}     // Pass claimId
          showDisclaimer={false}
          showSearch={false}
          fetchResponseText={false}
          docId={selectedDocId}
          iceAuthorizedAmount={iceAuthorizedAmount}
      />

      {isBlacklisted && (
        <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
          <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
        </div>
      )}

      <div className="details-section">
        <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
          <h2 className="ice-deductions-heading">ICE Deductions</h2>
          {documentStatus.slice(1)}
        </div>
        <div className="documents">
          <ul>
            {documentLinks}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ClaimDetailsPage;





