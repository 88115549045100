// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
// import './App.css';
// import UploadSection from './components/UploadSection';
// import AnimationPage from './components/AnimationPage';
// import ClaimDetailsPage from './components/ClaimDetailsPage';
// import AuditDetailsPage from './components/AuditDetailsPage';
// import TariffDetails from './components/TariffDetails';
// import DummyAnimationPage from './components/DummyAnimationPage';
// import SettlementLetter from './components/SettlementLetter';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'typeface-manrope';
// import JobPipeline from './components/JobPipeline';
// import DashboardIcon from './icons/dashboard-icon.svg';
// import TpaIcon from './icons/tpa-icon.svg';
// import AuditClaimIcon from './icons/audit-claim-icon.svg';
// import AiAssistantIcon from './icons/ai-assistant-icon.svg';
// import ChevronDownIcon from './icons/chevron-down-icon.svg';
// import DataExtractionIcon from './icons/filtering.svg';
// import { API_ENDPOINTS } from './config';
// import FileUploadPage from './components/FileUploadPage';
// import JsonDisplayPage from './components/JsonDisplayPage';
// import AccessPage from './components/AccessPage';
// import LoginPage from './components/LoginPage';
// import LandingPage from "./components/LandingPage";

// function App() {

//   // <Route path="/" element={<UploadPage />} />
//   // const location = useLocation();
//   // const hideSidebarRoutes = ['/land', '/access', '/login'];

//   // const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);
//   return (
//     <Router>
//       <div className="app">
//         <Sidebar />
//         <Routes>
//         <Route
//             path="/land"
//             element={
//               <>
//                 <LandingPage/> {/* Landing Page Hero Section */}
//               </>
//             }
//           />
//           <Route path="/" element={<UploadPage />} />
//           <Route path="/access" element={<AccessPage />} />
//           <Route path="/login" element={<LoginPage />} />
//           <Route path="/animation" element={<AnimationPage />} />
//           <Route path="/claim-details" element={<ClaimDetailsPage />} />
//           <Route path="/document/:docId" element={<AuditDetailsPage />} />
//           <Route path="/settlement/:documentId" element={<TariffDetails />} />
//           <Route path="/dummy" element={<DummyAnimationPage />} />
//           <Route path="/pipe" element={<JobPipeline />} />
//           <Route path="/demo_upload" element={<FileUploadPage />} />
//           <Route path="/json-display" element={<JsonDisplayPage />} />

//           <Route
//           path="/settlement-letter"
//           element={
//             <SettlementLetter
//               recipientName="John Doe"
//               recipientAddress="123 Main St, Anytown, USA"
//               claimReferenceNumber="XYZ123456789"
//             />              
//             }
//           />
//         </Routes>
//       </div>
//     </Router>
//   );
// }


// // hide the sidebar on the landing page
// // const App = () => {
// //   return (
// //     <Router>
// //       <MainLayout />
// //     </Router>
// //   );
// // };

// // const MainLayout = () => {
// //   const location = useLocation();
// //   const hideSidebarRoutes = ['/land', '/access', '/login'];

// //   const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);

// //   return (
// //     <div className="app">
// //       {!shouldHideSidebar && <Sidebar />}
// //       <div className="content">
// //         <Routes>
// //           <Route path="/land" element={<LandingPage />} />
// //           <Route path="/" element={<UploadSection />} />
// //           <Route path="/access" element={<AccessPage />} />
// //           <Route path="/login" element={<LoginPage />} />
// //           <Route path="/animation" element={<AnimationPage />} />
// //           <Route path="/claim-details" element={<ClaimDetailsPage />} />
// //           <Route path="/document/:docId" element={<AuditDetailsPage />} />
// //           <Route path="/settlement/:documentId" element={<TariffDetails />} />
// //           <Route path="/dummy" element={<DummyAnimationPage />} />
// //           <Route path="/pipe" element={<JobPipeline />} />
// //           <Route path="/demo_upload" element={<FileUploadPage />} />
// //           <Route path="/json-display" element={<JsonDisplayPage />} />
// //           <Route
// //             path="/settlement-letter"
// //             element={
// //               <SettlementLetter
// //                 recipientName="John Doe"
// //                 recipientAddress="123 Main St, Anytown, USA"
// //                 claimReferenceNumber="XYZ123456789"
// //               />
// //             }
// //           />
// //         </Routes>
// //       </div>
// //     </div>
// //   );
// // };


// // function Sidebar() {
// //   const navigate = useNavigate();
// //   const [isAuditDropdownOpen, setIsAuditDropdownOpen] = useState(false);

// //   const handleNavigation = (path) => {
// //     navigate(path);
// //     setIsAuditDropdownOpen(false); // Close the dropdown after navigation
// //   };

// //   const toggleAuditDropdown = () => {
// //     setIsAuditDropdownOpen(!isAuditDropdownOpen);
// //   };

// //   return (
// //     <div className="sidebar" onMouseLeave={() => setIsAuditDropdownOpen(false)}>
// //       <div className="sidebar-logo">
// //         <img src="/logonew.svg" alt="Logo" />
// //       </div>
// //       <ul className="sidebar-menu">
// //       <li onClick={() => handleNavigation('/demo_upload')}>
// //           <img className="extract-icon" src={DataExtractionIcon} alt="Extracting Claim data" />
// //           <span>Extract Claim Data</span>
// //         </li>
// //         <li onClick={() => handleNavigation('/')}>
// //           <img src={DashboardIcon} alt="Adjudicate Claim" />
// //           <span>Adjudicate Claim</span>
// //         </li>
// //         <li onClick={() => handleNavigation('/tpas')}>
// //           <img src={AuditClaimIcon} alt="TPAs" />
// //           <span>Search Claims</span>
// //         </li>
// //         {/* <li className="menu-item" onMouseEnter={() => setIsAuditDropdownOpen(true)}>
// //           <div>
// //             <img src={AuditClaimIcon} alt="Audit Claim" />
// //             <span>Audit Claim</span>
// //           </div>
// //           {isAuditDropdownOpen && (
// //             <ul className="submenu">
// //               <li onClick={() => handleNavigation('/claim-details')}>Individual</li>
// //               <li onClick={() => handleNavigation('/bulk')}>Bulk</li>
// //             </ul>
// //           )}
// //         </li> */}
// //         <li onClick={() => handleNavigation('/ai-assistant')}>
// //           <img className='ai' src={AiAssistantIcon} alt="AI Assistant" />
// //           <span>AI Assistant</span>
// //         </li>
// //       </ul>
// //     </div>
// //   );
// // }



// function Sidebar() {
//   const navigate = useNavigate();
//   const [isAuditDropdownOpen, setIsAuditDropdownOpen] = useState(false);

//   const handleNavigation = (path) => {
//     navigate(path);
//     setIsAuditDropdownOpen(false); // Close the dropdown after navigation
//   };

//   const toggleAuditDropdown = () => {
//     setIsAuditDropdownOpen(!isAuditDropdownOpen);
//   };

//   const isButtonDisabled = (path) => {
//     // Disable all buttons except for the extract claim data button ('/demo_upload')
//     return path !== '/demo_upload';
//   };

//   return (
//     <div className="sidebar" onMouseLeave={() => setIsAuditDropdownOpen(false)}>
//       <div className="sidebar-logo">
//         <img src="/logonew.svg" alt="Logo" />
//       </div>
//       <ul className="sidebar-menu">
//         <li
//           onClick={() => !isButtonDisabled('/demo_upload') && handleNavigation('/demo_upload')}
//           // className={!isButtonDisabled('/demo_upload') ? '' : 'disabled'}
//           className={isButtonDisabled('/') ? 'disabled' : ''}
//         >
//           <img className="extract-icon" src={DataExtractionIcon} alt="Extracting Claim data" />
//           <span>Extract Claim Data</span>
//         </li>
//         <li
//           onClick={() => !isButtonDisabled('/') && handleNavigation('/')}
//           className={isButtonDisabled('/') ? 'disabled' : ''}
//         >
//           <img src={DashboardIcon} alt="Adjudicate Claim" />
//           <span>Adjudicate Claim</span>
//         </li>
//         <li
//           onClick={() => !isButtonDisabled('/tpas') && handleNavigation('/tpas')}
//           className={isButtonDisabled('/tpas') ? 'disabled' : ''}
//         >
//           <img src={AuditClaimIcon} alt="TPAs" />
//           <span>Search Claims</span>
//         </li>
//         <li
//           onClick={() => !isButtonDisabled('/ai-assistant') && handleNavigation('/ai-assistant')}
//           className={isButtonDisabled('/ai-assistant') ? 'disabled' : ''}
//         >
//           <img className="ai" src={AiAssistantIcon} alt="AI Assistant" />
//           <span>AI Assistant</span>
//         </li>
//       </ul>
//     </div>
//   );
// }


// function UploadPage() {
//   const [patientId, setPatientId] = useState('');
//   const [claimId, setClaimId] = useState('');
//   const [isBackendReady, setIsBackendReady] = useState(false);
//   const [isEmailPopupVisible, setIsEmailPopupVisible] = useState(false); // Popup trigger
//   const [email, setEmail] = useState(''); // Email state
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Poll the backend every 2 seconds until we get a successful response
//     const intervalId = setInterval(async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/hello`);
//         if (response.ok) {
//           setIsBackendReady(true);
//           clearInterval(intervalId);
//         } else {
//           console.error('Backend is not ready yet.');
//         }
//       } catch (error) {
//         console.error('Error checking backend readiness:', error);
//       }
//     }, 2000);

//     return () => clearInterval(intervalId); // Cleanup
//   }, []);

//   const handleFileUpload = async (title, files) => {
//     if (!isBackendReady) {
//       alert('Backend is not ready yet. Please wait.');
//       return;
//     }

//     const file = files[0]; // Assume single file upload
//     const fileName = file.name;
//     const contentType = file.type;

//     try {
//       // Request presigned URL from backend
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/generate-presigned-url`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         body: new URLSearchParams({
//           title,
//           file_name: fileName,
//           content_type: contentType,
//           ice_patient_id: patientId || '', // Pass patientId if it exists
//           ice_claim_id: claimId || '',     // Pass claimId if it exists
//         }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         // Save patientId and claimId if they were generated
//         if (!patientId) setPatientId(data.ice_patient_id);
//         if (!claimId) setClaimId(data.ice_claim_id);

//         // Upload the file to S3 using the presigned URL
//         const s3Response = await fetch(data.presigned_url, {
//           method: 'PUT',
//           headers: { 'Content-Type': contentType },
//           body: file,
//         });

//         if (s3Response.ok) {
//           console.log(`File uploaded successfully: ${fileName}`);
//         } else {
//           console.log('Failed to upload file to S3.');
//         }
//       } else {
//         console.log(`Error: ${data.detail}`);
//       }
//     } catch (error) {
//       console.error('Error during file upload:', error);
//       alert('Failed to upload file. Please try again.');
//     }
//   };


//     const handleNextClick = () => {
//     if (!patientId || !claimId) {
//       alert('Please upload at least one document.');
//       return;
//     }
//     // Navigate to the next page, passing IDs
//     navigate('/animation', { state: { patientId, claimId } });
//   };

//   return (
//     <div className="main-content">
//       <header className="upload-page-header">
//         <h1>Welcome</h1>
//         <p>To audit an individual claim, upload the framework documents.</p>
//       </header>

//       <div className="upload-sections">
//         <UploadSection
//           title="Claim Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId} // Pass the patientId
//           claimId={claimId}     // Pass the claimId
//         />
//         <UploadSection
//           title="Policy Wording Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           // disabled={true} // Always disabled
//           patientId={patientId} // Pass the patientId
//           claimId={claimId}     // Pass the claimId
//         />
//         <UploadSection
//           title="Policy Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           // disabled={true} // Always disabled
//           patientId={patientId} // Pass the patientId
//           claimId={claimId}     // Pass the claimId
//         />
//         <UploadSection
//           title="STG Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           // disabled={true} // Always disabled

//           patientId={patientId} // Pass the patientId
//           claimId={claimId}     // Pass the claimId
//         />
//         <UploadSection
//           title="Tariff Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           // disabled={true} // Always disabled
//           patientId={patientId} // Pass the patientId
//           claimId={claimId}     // Pass the claimId
//         />
//         <UploadSection
//           title="Settlement Letter"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           // disabled={true} // Always disabled
//           patientId={patientId} // Pass the patientId
//           claimId={claimId}     // Pass the claimId
//         />
//       </div>

//       <button
//         className="next-button"
//         onClick={handleNextClick}
//         disabled={!claimId || !isBackendReady}
//       >
//         Next
//       </button>
//     </div>
//   );
// }

// export default App;





// testing

// App.js
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'typeface-manrope';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UploadSection from './components/UploadSection';
import AnimationPage from './components/AnimationPage';
import ClaimDetailsPage from './components/ClaimDetailsPage';
import AuditDetailsPage from './components/AuditDetailsPage';
import TariffDetails from './components/TariffDetails';
import DummyAnimationPage from './components/DummyAnimationPage';
import SettlementLetter from './components/SettlementLetter';
import JobPipeline from './components/JobPipeline';
import FileUploadPage from './components/FileUploadPage';
import JsonDisplayPage from './components/JsonDisplayPage';
import AccessPage from './components/AccessPage';
import LoginPage from './components/LoginPage';
import LandingPage from './components/LandingPage';

import DashboardIcon from './icons/dashboard-icon.svg';
import TpaIcon from './icons/tpa-icon.svg';
import AuditClaimIcon from './icons/audit-claim-icon.svg';
import AiAssistantIcon from './icons/ai-assistant-icon.svg';
import ChevronDownIcon from './icons/chevron-down-icon.svg';
import DataExtractionIcon from './icons/filtering.svg';
import { API_ENDPOINTS } from './config';

/* ----------------------------------
 * Sidebar Component
 * ---------------------------------- */
function Sidebar() {
  const navigate = useNavigate();
  const [isAuditDropdownOpen, setIsAuditDropdownOpen] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
    setIsAuditDropdownOpen(false); // Close the dropdown after navigation
  };

  const toggleAuditDropdown = () => {
    setIsAuditDropdownOpen(!isAuditDropdownOpen);
  };

  // Example logic: only /demo_upload is enabled, others are disabled
  const isButtonDisabled = (path) => {
    return path !== '/demo_upload';
  };

  return (
    <div className="sidebar" onMouseLeave={() => setIsAuditDropdownOpen(false)}>
      <div className="sidebar-logo">
        <img src="/logonew.svg" alt="Logo" />
      </div>
      <ul className="sidebar-menu">
        <li
          onClick={() => !isButtonDisabled('/demo_upload') && handleNavigation('/demo_upload')}
          className={isButtonDisabled('/') ? 'disabled' : ''}
        >
          <img className="extract-icon" src={DataExtractionIcon} alt="Extracting Claim data" />
          <span>Extract Claim Data</span>
        </li>
        <li
          onClick={() => !isButtonDisabled('/') && handleNavigation('/')}
          className={isButtonDisabled('/') ? 'disabled' : ''}
        >
          <img src={DashboardIcon} alt="Adjudicate Claim" />
          <span>Adjudicate Claim</span>
        </li>
        <li
          onClick={() => !isButtonDisabled('/tpas') && handleNavigation('/tpas')}
          className={isButtonDisabled('/tpas') ? 'disabled' : ''}
        >
          <img src={AuditClaimIcon} alt="TPAs" />
          <span>Search Claims</span>
        </li>
        <li
          onClick={() => !isButtonDisabled('/ai-assistant') && handleNavigation('/ai-assistant')}
          className={isButtonDisabled('/ai-assistant') ? 'disabled' : ''}
        >
          <img className="ai" src={AiAssistantIcon} alt="AI Assistant" />
          <span>AI Assistant</span>
        </li>
      </ul>
    </div>
  );
}

/* ----------------------------------
 * UploadPage Component
 * ---------------------------------- */
// function UploadPage() {
//   const [patientId, setPatientId] = useState('');
//   const [claimId, setClaimId] = useState('');
//   const [isNotificationVisible, setIsNotificationVisible] = useState(true); // Added for notification visibility
//   const [isBackendReady, setIsBackendReady] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Poll the backend every 2 seconds until we get a successful response
//     const intervalId = setInterval(async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/hello`);
//         if (response.ok) {
//           setIsBackendReady(true);
//           setIsNotificationVisible(false); // Hide the notification once the backend is ready
//           clearInterval(intervalId);
//         } else {
//           console.error('Backend is not ready yet.');
//         }
//       } catch (error) {
//         console.error('Error checking backend readiness:', error);
//       }
//     }, 2000);

//     return () => clearInterval(intervalId); // Cleanup
//   }, []);

//   const handleFileUpload = async (title, files) => {
//     if (!isBackendReady) {
//       alert('Backend is not ready yet. Please wait.');
//       return;
//     }

//     const file = files[0]; // single file
//     const fileName = file.name;
//     const contentType = file.type;

//     try {
//       // 1. Request presigned URL from backend
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/generate-presigned-url`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         body: new URLSearchParams({
//           title,
//           file_name: fileName,
//           content_type: contentType,
//           ice_patient_id: patientId || '',
//           ice_claim_id: claimId || '',
//         }),
//       });

//       const data = await response.json();

//       if (response.ok) {
//         // Save patientId and claimId if generated
//         if (!patientId) setPatientId(data.ice_patient_id);
//         if (!claimId) setClaimId(data.ice_claim_id);

//         // 2. Upload file to S3 using the presigned URL
//         const s3Response = await fetch(data.presigned_url, {
//           method: 'PUT',
//           headers: { 'Content-Type': contentType },
//           body: file,
//         });

//         if (s3Response.ok) {
//           console.log(`File uploaded successfully: ${fileName}`);
//         } else {
//           console.log('Failed to upload file to S3.');
//         }
//       } else {
//         console.log(`Error: ${data.detail}`);
//       }
//     } catch (error) {
//       console.error('Error during file upload:', error);
//       alert('Failed to upload file. Please try again.');
//     }
//   };

//   const handleNextClick = () => {
//     if (!patientId || !claimId) {
//       alert('Please upload at least one document.');
//       return;
//     }
//     navigate('/animation', { state: { patientId, claimId } });
//   };

//   return (
//     <div className="main-content">
//       {/* notfication section only appears during backend checks */}
//       {isNotificationVisible && (
//           <div className="notification">
//             <div className="notification-content">
//               <i className="fas fa-info-circle info-icon" aria-hidden="true"></i>
//               <span className="notification-text">ICE Engine is starting...</span>
//               <i 
//                 className="fas fa-times close-icon" 
//                 onClick={() => setIsNotificationVisible(false)}
//                 aria-hidden="true"
//               ></i>
//             </div>
//             {/* Progress bar only appears during backend checks */}
//             {!isBackendReady && (
//               <div className="progress-bar">
//                 <div className="progress"></div>
//               </div>
//             )}
//           </div>
//         )}
//       <header className="upload-page-header">
//         <h1>Welcome</h1>
//         <p>To audit an individual claim, upload the framework documents.</p>
//       </header>

//       <div className="upload-sections">
//         <UploadSection
//           title="Claim Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId}
//           claimId={claimId}
//         />
//         <UploadSection
//           title="Policy Wording Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId}
//           claimId={claimId}
//         />
//         <UploadSection
//           title="Policy Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId}
//           claimId={claimId}
//         />
//         <UploadSection
//           title="STG Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId}
//           claimId={claimId}
//         />
//         <UploadSection
//           title="Tariff Document"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId}
//           claimId={claimId}
//         />
//         <UploadSection
//           title="Settlement Letter"
//           formats="PNG, JPG, PDF"
//           onFileUpload={handleFileUpload}
//           disabled={!isBackendReady}
//           patientId={patientId}
//           claimId={claimId}
//         />
//       </div>

//       <button
//         className="next-button"
//         onClick={handleNextClick}
//         disabled={!claimId || !isBackendReady}
//       >
//         Next
//       </button>
//     </div>
//   );
// }

function UploadPage() {
  const [patientId, setPatientId] = useState('');
  const [claimId, setClaimId] = useState('');
  const [isBackendReady, setIsBackendReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let toastId;
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/hello`);
        if (response.ok) {
          setIsBackendReady(true);
          toast.dismiss(toastId); // Dismiss the loading toast
          toast.success('ICE Engine is ready!', { autoClose: 3000 }); // Show success toast
          clearInterval(intervalId);
        } else {
          console.error('Backend is not ready yet.');
        }
      } catch (error) {
        console.error('Error checking backend readiness:', error);
      }
    }, 2000);

    // Show loading toast when the backend check starts
    toastId = toast.info('ICE Engine is starting...', {
      autoClose: false, // Keep the toast open until dismissed
      closeButton: false, // Hide the close button
    });

    return () => {
      clearInterval(intervalId);
      toast.dismiss(toastId); // Cleanup toast on unmount
    };
  }, []);

  const handleFileUpload = async (title, files) => {
    if (!isBackendReady) {
      toast.error('Backend is not ready yet. Please wait.', { autoClose: 3000 });
      return;
    }

    const file = files[0]; // single file
    const fileName = file.name;
    const contentType = file.type;

    try {
      // 1. Request presigned URL from backend
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/generate-presigned-url`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          title,
          file_name: fileName,
          content_type: contentType,
          ice_patient_id: patientId || '',
          ice_claim_id: claimId || '',
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Save patientId and claimId if generated
        if (!patientId) setPatientId(data.ice_patient_id);
        if (!claimId) setClaimId(data.ice_claim_id);

        // 2. Upload file to S3 using the presigned URL
        const s3Response = await fetch(data.presigned_url, {
          method: 'PUT',
          headers: { 'Content-Type': contentType },
          body: file,
        });

        if (s3Response.ok) {
          toast.success(`File uploaded successfully: ${fileName}`, { autoClose: 3000 });
        } else {
          toast.error('Failed to upload file to S3.', { autoClose: 3000 });
        }
      } else {
        toast.error(`Error: ${data.detail}`, { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error during file upload:', error);
      toast.error('Failed to upload file. Please try again.', { autoClose: 3000 });
    }
  };

  const handleNextClick = () => {
    if (!patientId || !claimId) {
      toast.error('Please upload at least one document.', { autoClose: 3000 });
      return;
    }
    navigate('/animation', { state: { patientId, claimId } });
  };

  return (
    <div className="main-content">
      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <header className="upload-page-header">
        <h1>Welcome</h1>
        <p>To audit an individual claim, upload the framework documents.</p>
      </header>

      <div className="upload-sections">
        <UploadSection
          title="Claim Document"
          formats="PNG, JPG, PDF"
          onFileUpload={handleFileUpload}
          disabled={!isBackendReady}
          patientId={patientId}
          claimId={claimId}
        />
        <UploadSection
          title="Policy Wording Document"
          formats="PNG, JPG, PDF"
          onFileUpload={handleFileUpload}
          disabled={!isBackendReady}
          patientId={patientId}
          claimId={claimId}
        />
        <UploadSection
          title="Policy Document"
          formats="PNG, JPG, PDF"
          onFileUpload={handleFileUpload}
          disabled={!isBackendReady}
          patientId={patientId}
          claimId={claimId}
        />
        <UploadSection
          title="STG Document"
          formats="PNG, JPG, PDF"
          onFileUpload={handleFileUpload}
          disabled={!isBackendReady}
          patientId={patientId}
          claimId={claimId}
        />
        <UploadSection
          title="Tariff Document"
          formats="PNG, JPG, PDF"
          onFileUpload={handleFileUpload}
          disabled={!isBackendReady}
          patientId={patientId}
          claimId={claimId}
        />
        <UploadSection
          title="Settlement Letter"
          formats="PNG, JPG, PDF"
          onFileUpload={handleFileUpload}
          disabled={!isBackendReady}
          patientId={patientId}
          claimId={claimId}
        />
      </div>

      <button
        className="next-button"
        onClick={handleNextClick}
        disabled={!claimId || !isBackendReady}
      >
        Next
      </button>
    </div>
  );
}

function App() {
  // console.log("Hostname:", window.location.hostname); // Debugging
  // console.log("isLandingDomain:", isLandingDomain); // Debugging

  const isLandingDomain =
    window.location.hostname === "icehealth.in" 
    // window.location.hostname === "localhost";

  return (
    <Router>
      <div className="app">
        {!isLandingDomain && <Sidebar />}
        <Routes>
          {isLandingDomain ? (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/access" element={<AccessPage />} />
              <Route path="*" element={<div>404 - Page Not Found</div>} /> {/* Fallback */}
            </>
          ) : (
            <>
              <Route path="/" element={<UploadPage />} />
              <Route path="/animation" element={<AnimationPage />} />
              <Route path="/claim-details" element={<ClaimDetailsPage />} />
              <Route path="/document/:docId" element={<AuditDetailsPage />} />
              <Route path="/settlement/:documentId" element={<TariffDetails />} />
              <Route path="/dummy" element={<DummyAnimationPage />} />
              <Route path="/pipe" element={<JobPipeline />} />
              <Route path="/demo_upload" element={<FileUploadPage />} />
              <Route path="/json-display" element={<JsonDisplayPage />} />
              <Route
                path="/settlement-letter"
                element={
                  <SettlementLetter
                    recipientName="John Doe"
                    recipientAddress="123 Main St, Anytown, USA"
                    claimReferenceNumber="XYZ123456789"
                  />
                }
              />
              <Route path="*" element={<div>404 - Page Not Found</div>} /> {/* Fallback */}
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;




/* ----------------------------------
 * for local development
 * ---------------------------------- */
// function MainLayout() {
//   const location = useLocation();

//   // Routes where the sidebar should be hidden
//   const hideSidebarRoutes = ['/land', '/access', '/login'];

//   // Determine if we should hide the sidebar on this route
//   const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);

//   return (
//     <div className="app">
//       {/* Conditionally render the sidebar */}
//       {!shouldHideSidebar && <Sidebar />}

//       {/* Main content area with all routes */}
//       <div className="content">
//         <Routes>
//           <Route path="/land" element={<LandingPage />} />
//           <Route path="/access" element={<AccessPage />} />
//           <Route path="/login" element={<LoginPage />} />
//           <Route path="/animation" element={<AnimationPage />} />
//           <Route path="/claim-details" element={<ClaimDetailsPage />} />
//           <Route path="/document/:docId" element={<AuditDetailsPage />} />
//           <Route path="/settlement/:documentId" element={<TariffDetails />} />
//           <Route path="/dummy" element={<DummyAnimationPage />} />
//           <Route path="/pipe" element={<JobPipeline />} />
//           <Route path="/demo_upload" element={<FileUploadPage />} />
//           <Route path="/json-display" element={<JsonDisplayPage />} />
//           <Route
//             path="/settlement-letter"
//             element={
//               <SettlementLetter
//                 recipientName="John Doe"
//                 recipientAddress="123 Main St, Anytown, USA"
//                 claimReferenceNumber="XYZ123456789"
//               />
//             }
//           />
//           {/* Main/Home Route */}
//           <Route path="/" element={<UploadPage />} />
//         </Routes>
//       </div>
//     </div>
//   );
// }

// /* ----------------------------------
//  * The App (entry point)
//  * ---------------------------------- */
// function App() {
//   return (
//     <Router>
//       <MainLayout />
//     </Router>
//   );
// }

// export default App;
